import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../components/common/PageTitle';
import { createStyle } from '../utils/tools';
import { useForm } from 'react-hook-form';
import qs from 'query-string';
import BackgroundImg from '../assets/images/red-packet/red-packet-promotion.jpg';
import InputField from '../components/common/input/InputField';
import { toast } from 'react-toastify';
import GetCode from '../components/Register/GetCode';
import Footer from '../components/common/layout/Footer';
import request from '../utils/request';
import { backGroundLight, subTextColor } from '../constant/Color';
import QRCodeModal from '../components/common/QRCodeModal';
import EventTitleImg from '../assets/images/red-packet/policy-title.png';
import RedPacketImg from '../assets/images/red-packet/red-packet.png';
import WeChatImg from '../assets/images/red-packet/wechat.png';
import LoadingModal from '../components/common/LoadingModal';

const phoneNumberRegex = RegExp(
  /(^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$)|^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[235-8]\d{2}|4(?:0\d|1[0-2]|9\d))|9[0-35-9]\d{2}|66\d{2})\d{6}$/
);

const PromotionReceive = () => {
  const { t, i18n } = useTranslation();
  const { register, handleSubmit, watch } = useForm({
    shouldFocusError: false,
  });
  const watchPhoneNumber: string | undefined = watch('phone');

  const [inviterInfo, setInviterInfo] = useState({
    current_user_count: 0,
    max_user_count: 0,
    wechat_qrcode: '',
  });
  const [isValidCode, setIsValidCode] = useState(false);
  const [shareCode, setShareCode] = useState('');
  const [redeemList, setRedeemList] = useState([]);
  const [isSentRedPacket, setIsSentRedPacket] = useState(false);
  const [coupon, setCoupon] = useState({
    expire_time: '',
    formated_cou_money: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const [showQRModal, setShowQRModal] = useState(false);

  useEffect(() => {
    //Get Invite Code from URL
    const queries = qs.parse(qs.extract(window.location.search));

    (async () => {
      if (queries.share_code) {
        if (typeof queries.share_code === 'string') {
          setShareCode(queries.share_code);
          const result = await getInviterInfo(queries.share_code);
          if (result && result.goodStatus && result.return_code === 200) {
            if (result.data && !result.data.is_available) {
              toast(t('redPacket.reachLimit'), {
                type: 'error',
              });
            } else {
              setInviterInfo({
                current_user_count: result.data.current_user_count,
                max_user_count: result.data.max_user_count,
                wechat_qrcode: result.data.other.wechat_qrcode,
              });
              setRedeemList(
                Array.isArray(result.data.coupon_user)
                  ? result.data.coupon_user
                  : []
              );
              setIsValidCode(true);
            }
          } else {
            toast(typeof result.data === 'string' ? result.data : 'Error', {
              type: 'error',
            });
          }
        } else {
          toast(t('redPacket.errors.missCode'), {
            type: 'error',
          });
        }
      } else {
        toast(t('redPacket.errors.missCode'), {
          type: 'error',
        });
      }
    })();
  }, [t]);

  useEffect(() => {
    if (showQRModal || isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isLoading, showQRModal]);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const result = await getRedPacket(data.phone, data.code, shareCode);
    if (result && result.goodStatus && result.return_code === 200) {
      setCoupon(result.data);
      setIsSentRedPacket(true);
    } else {
      toast(`${result.return_code}: ${result.data}`, {
        type: 'error',
      });
    }
    setIsLoading(false);
  };
  const onError = (errors: any) => {
    toast.clearWaitingQueue();
    if (errors.phone) {
      toast(errors.phone.message, { type: 'error' });
    } else if (errors.code) {
      toast(errors.code.message, { type: 'error' });
    }
    setIsLoading(false);
  };

  const onClickToShowQR = () => {
    setShowQRModal(true);
  };

  const onClickToDownload = () => {
    window.location.href = 'https://app.luniumall.com/home';
  };

  return (
    <div className="bg" style={styles.bg}>
      <div style={styles.root}>
        <main style={styles.container}>
          <PageTitle title={t('redPacket.title')} />
          {isValidCode && (
            <section>
              <div style={styles.card}>
                {isSentRedPacket && (
                  <div
                    style={{
                      flex: 1,
                      padding: 20,
                      backgroundColor: '#fffac1',
                      borderRadius: 8,
                    }}
                  >
                    <div style={styles.coupon}>
                      <div style={styles.amountRow}>
                        <div>
                          <p
                            style={{
                              margin: 0,
                              fontSize: 18,
                              color: '#fe453d',
                            }}
                          >
                            {t('redPacket.coupon.amount')}:
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              margin: 0,
                              fontSize: 36,
                              color: '#fe453d',
                            }}
                          >
                            {coupon.formated_cou_money}
                          </p>
                        </div>
                      </div>
                      <div style={styles.expireRow}>
                        <p
                          style={{
                            margin: 0,
                            fontSize: 12,
                            color: subTextColor,
                          }}
                        >
                          {t('redPacket.coupon.expiredDate')}:{' '}
                          {coupon.expire_time}
                        </p>
                        <InputField
                          type="submit"
                          style={styles.couponButton}
                          placeholder={t('redPacket.coupon.useBalance')}
                          onClick={onClickToDownload}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  {isSentRedPacket ? (
                    <form
                      style={styles.form}
                      onSubmit={handleSubmit(onClickToDownload)}
                    >
                      <InputField
                        type="submit"
                        placeholder={t('redPacket.coupon.useIt')}
                        style={styles.redeemButton}
                      />
                    </form>
                  ) : (
                    <form
                      onSubmit={handleSubmit(onSubmit, onError)}
                      style={styles.form}
                    >
                      <InputField
                        name="phone"
                        type="tel"
                        refValue={register({
                          required: `${t(
                            'register.validation.enterPhoneNumber'
                          )}`,
                          pattern: {
                            message: `${t('register.validation.invalidPhone')}`,
                            value: phoneNumberRegex,
                          },
                        })}
                        placeholder={t('register.validation.enterPhoneNumber')}
                        // leftComponent={<PhoneRegionSelector />}
                      />
                      <InputField
                        name="code"
                        type="text"
                        refValue={register({
                          required: `${t('register.validation.enterCode')}`,
                        })}
                        placeholder={t('register.validation.codeHint')}
                        rightComponent={
                          <GetCode
                            enableBtn={
                              typeof watchPhoneNumber === 'string' &&
                              phoneNumberRegex.test(watchPhoneNumber)
                            }
                            phoneNumber={watchPhoneNumber}
                          />
                        }
                        maxLength={6}
                      />
                      <InputField
                        type="submit"
                        placeholder={t('redPacket.getRedPacket')}
                        style={styles.redeemButton}
                      />
                    </form>
                  )}
                </div>
              </div>
            </section>
          )}
          {isValidCode && redeemList.length ? (
            <section>
              <div style={styles.card}>
                <div style={styles.redeemList}>
                  <h4 style={styles.redeemListTitle}>
                    {t('redPacket.redeemList.title')}{' '}
                    <span
                      style={{
                        fontWeight: 'normal',
                        color: subTextColor,
                        fontSize: 12,
                      }}
                    >
                      ({t('redPacket.redeemList.received')}{' '}
                      {inviterInfo.current_user_count}/
                      {inviterInfo.max_user_count})
                    </span>
                  </h4>
                  {redeemList.length &&
                    redeemList.map((item: any, i) => (
                      <div style={styles.redeemListItem} key={i}>
                        <div style={{ flexShrink: 1 }}>
                          <img
                            style={styles.redeemImg}
                            src={item.user.user_picture}
                            alt="snapshot"
                          />
                        </div>
                        <div
                          style={{
                            ...styles.redeemListInfo,
                            ...{ flexGrow: 1 },
                          }}
                        >
                          <p style={{ margin: 0, fontSize: 12 }}>
                            {item.user.nick_name}
                          </p>
                        </div>
                        <div
                          style={{
                            ...styles.redeemListInfo,
                            ...{ flexShrink: 1 },
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              fontSize: 12,
                            }}
                          >
                            {item.formated_cou_money}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </section>
          ) : (
            <></>
          )}
          <section className="eventDetail" style={styles.eventDetailContainer}>
            <div style={styles.card}>
              {i18n.language.toLowerCase() === 'en-us' ||
              i18n.language.toLowerCase() === 'en' ? (
                <h3 style={styles.eventDetailTitle}>Event Details</h3>
              ) : (
                <div style={styles.eventDetailTitle}>
                  <img src={RedPacketImg} alt="red-packet" width="10%" />
                  <img
                    src={EventTitleImg}
                    width="40%"
                    alt="event-title"
                    style={{ marginRight: 5 }}
                  />
                  <img src={RedPacketImg} alt="red-packet" width="10%" />
                </div>
              )}
              <ul style={styles.eventDetailList}>
                <li style={styles.eventDetailListItem}>
                  {t('redPacket.eventDetail.descriptions.1')}
                </li>
                <li style={styles.eventDetailListItem}>
                  {t('redPacket.eventDetail.descriptions.2')}
                </li>
                <li style={styles.eventDetailListItem}>
                  {t('redPacket.eventDetail.descriptions.3')}
                </li>
                <li style={styles.eventDetailListItem}>
                  {t('redPacket.eventDetail.descriptions.4')}
                </li>
                <li style={styles.eventDetailListItem}>
                  {t('redPacket.eventDetail.descriptions.5')}
                </li>
                <li style={styles.eventDetailListItem}>
                  {t('redPacket.eventDetail.descriptions.6')}
                </li>
                <li style={styles.eventDetailListItem}>
                  {t('redPacket.eventDetail.descriptions.7')}
                </li>
              </ul>
            </div>
          </section>
        </main>
        <Footer backgroundColor="#fe453d" textColor="white" />
        <LoadingModal show={isLoading} />
        <QRCodeModal
          show={showQRModal}
          onClose={() => setShowQRModal(false)}
          imgURL={inviterInfo.wechat_qrcode}
          lang={i18n.language}
        />
        <img
          src={WeChatImg}
          alt="wechat-logo"
          style={styles.weChatButton}
          onClick={onClickToShowQR}
        />
      </div>
    </div>
  );
};

const MAX_WIDTH = 414;

// const ratio = window.innerWidth >= MAX_WIDTH ? 500 : 550;

const styles = createStyle({
  bg: {
    // backgroundColor: '#FDAE7E',
  },
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#fe453d',
    backgroundImage: `url(${BackgroundImg})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    maxWidth: MAX_WIDTH,
    margin: '0 auto',
  },
  container: {
    // backgroundColor: '#FDAE7E',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: '100%',
    paddingLeft: 15,
    paddingRight: 15,
  },
  title: {
    textAlign: 'center',
    opacity: 0,
    position: 'absolute',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: MAX_WIDTH,
    // margin: '0 auto',
    width: '100%',
  },
  eventDetailContainer: {
    maxWidth: MAX_WIDTH,
    // padding: '.2rem 0',
    color: 'black',
    backgroundColor: '#fffac1',
    borderRadius: 8,
    marginTop: 10,
  },
  eventDetailTitle: {
    textAlign: 'center',
    margin: 0,
    marginTop: 15,
  },
  eventDetailList: {
    margin: 0,
    padding: 5,
    fontSize: '.8rem',
    listStyle: 'none',
    marginBottom: 10,
  },
  eventDetailListItem: {
    marginTop: 12,
    marginLeft: 10,
    marginRight: 10,
  },
  regionSelectorContainer: {
    fontSize: 12,
    borderRightColor: backGroundLight,
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    paddingRight: 12,
    position: 'relative',
    marginRight: 6,
  },
  regionSelector: {
    all: 'unset',
    WebkitAppearance: 'none',
    outline: 'none',
    border: 'none',
    backgroundColor: 'none',
  },
  arrowIcon: {
    position: 'absolute',
    right: 3,
    top: '25%',
  },
  inviterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inviterImg: {
    borderRadius: '50%',
    width: 60,
    height: 60,
  },
  redeemImg: {
    borderRadius: '50%',
    width: 40,
    height: 40,
    display: 'block',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  card: {
    // backgroundColor: subColorLight,
    flex: 1,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 10,
  },
  coupon: {
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 8,
    boxShadow: '0 0 5px 5px rgba(0,0,0,0.3)',
  },
  amountRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: subTextColor,
  },
  expireRow: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  couponButton: {
    backgroundColor: 'white',
    color: '#fe453d',
    borderColor: '#fe453d',
    borderWidth: 1,
    borderStyle: 'solid',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 10,
    fontSize: 9,
    margin: 0,
  },
  redeemButton: {
    backgroundColor: '#ffde75',
    color: 'black',
    fontWeight: 'bold',
  },
  button: {
    background: 'transparent',
    boxShadow: '0px 0px 0px transparent',
    border: '0px solid transparent',
    textShadow: '0px 0px 0px transparent',
    width: '40%',
  },
  redeemList: {
    padding: 10,
    backgroundColor: '#fffac1',
    borderRadius: 8,
  },
  redeemListTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    margin: '0 0 10px 0',
  },
  redeemListItem: {
    display: 'flex',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: subTextColor,
    paddingTop: 5,
    paddingBottom: 5,
  },
  redeemListInfo: {
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  weChatButton: {
    position: 'sticky',
    bottom: '10%',
    left: '82%',
    width: 45,
    boxShadow: '0 0 5px 5px rgba(0,0,0,0.3)',
    borderRadius: 8,
  },
});

const getRedPacket = async (
  phone: string,
  code: string,
  share_code: string
) => {
  const result = await request.post('red_packet/add', {
    params: {
      share_code: share_code,
    },
    data: {
      mobile_phone: phone,
      code: code,
    },
  });
  return result;
};

// const PhoneRegionSelector = () => {
//   const { t } = useTranslation();
//   return (
//     <div style={styles.regionSelectorContainer}>
//       <select name="region" style={styles.regionSelector}>
//         <option value="NA">{t('register.validation.region.NA')}</option>
//         <option value="CN">{t('register.validation.region.CN')}</option>
//       </select>
//       <FontAwesomeIcon icon={faAngleDown} style={styles.arrowIcon} />
//     </div>
//   );
// };

const getInviterInfo = async (code: string) => {
  const result = await request.get('red_packet', {
    params: {
      share_code: code,
    },
  });

  return result;
};

export default PromotionReceive;
