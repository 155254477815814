import Register from './register.json';
import Download from './download.json';
import cardVerification from './cardVerification.json';
import RedPacket from './redPacket.json';

export const translationZhCN = {
  register: Register,
  download: Download,
  cardVerification: cardVerification,
  redPacket: RedPacket,
};
